<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('user_model_filters')"
                        :isNewButton="checkPermission('usermodelfilter_store')"
                        @new-button-click="createFormShow"
                        @filter-div-status="datatable.filterStatus=$event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('user_model_filters')"
                              :isNewButton="checkPermission('usermodelfilter_store')"
                              @new-button-click="createFormShow"
                              @filter-div-status="datatable.filterStatus=$event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="4">
                        <b-form-group :label="$t('staff')">
                            <staff-auto-complete
                                v-model="datatable.queryParams.filter.user_id" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group :label="$t('user_model')">
                            <parameter-selectbox
                                code="user_filter_models"
                                v-model="datatable.queryParams.filter.model" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group :label="$t('user_filter_type')">
                            <parameter-selectbox
                                code="user_filter_types"
                                v-model="datatable.queryParams.filter.filter_type" />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="datatable.queryParams.filter.filter_type == 'campus_id'" sm="4">
                        <b-form-group :label="$t('campus')">
                            <campus-selectbox
                                v-model="datatable.queryParams.filter.filter_value" />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="datatable.queryParams.filter.filter_type == 'nationality'" sm="4">
                        <b-form-group :label="$t('nationality')">
                            <user-model-filter-nationality-selectbox
                                v-model="datatable.queryParams.filter.filter_value" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                valueType="bool"
                                v-model="datatable.queryParams.filter.status" />
                        </b-form-group>
                    </b-col>

                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>

            <CommonModal ref="showModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('faculty_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('view') }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>

            <CommonModal ref="createFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
//Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete"
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox"
import UserModelFilterNationalitySelectbox from "@/components/interactive-fields/UserModelFilterNationalitySelectbox"
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox"
//Services
import UserModelFilterService from "@/services/UserModelFilterService";
//Page
import CreateForm from "./CreateForm"
import UpdateForm from "./UpdateForm"
import ShowForm from "./ShowForm"
//Other
import qs from 'qs'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        StaffAutoComplete,
        ParameterSelectbox,
        CampusSelectbox,
        UserModelFilterNationalitySelectbox,
        StatusSelectbox,

        DatatableFilter,
        Datatable,
        CommonModal,
        ShowForm,
        CreateForm,
        UpdateForm
    },
    metaInfo() {
        return {
            title: this.$t('user_model_filters')
        }
    },
    data() {
        return {
            id: 0,
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [

                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'usermodelfilter_show',
                                callback: (row) => {
                                    this.showForm(row.id);
                                }
                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: 'usermodelfilter_update',
                                callback: (row) => {
                                    this.loadData(row.id);
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: 'usermodelfilter_delete',
                                callback: (row) => {
                                    this.delete(row.id);
                                }
                            },
                        ]
                    },
                    {
                        label: this.$t('staff'),
                        field: 'user',
                        sortable: true,
                        formatFn: (val) => {
                            return val.name+' '+val.surname
                        }
                    },
                    {
                        label: this.$t('user_model'),
                        field: ('model_name'),
                        sortable: false,
                    },
                    {
                        label: this.$t('user_filter_type'),
                        field: ('filter_type_name'),
                        sortable: false,
                    },
                    {
                        label: this.$t('user_filter_value'),
                        field: ('filter_value_name'),
                        sortable: false,
                    },
                    {
                        label: this.$t('status'),
                        field: 'status',
                        sortable: false,
                        formatFn: (val) => {
                            return val == 1 ? this.$t('active') : this.$t('passive')
                        }
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId:0
        }
    },
    methods: {
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true
            this.datatable.isLoading = true

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return UserModelFilterService.getAll(config)
                                .then((response) => {
                                    this.datatable.rows = response.data.data
                                    this.datatable.total = response.data.pagination.total
                                })
                                .finally(() => {
                                    this.datatable.isLoading = false;
                                });
        },
        loadData(id) {
            this.formId = id;
            this.formProcess='update';
            this.$refs.updateFormModal.$refs.commonModal.show();
        },
        showErrors(error) {
            if (error.status == 422) {

                if (error.data.errors.name) {
                    this.$refs.storeForm.errors.name.push(error.data.errors.name[0]);
                }
                if (error.data.errors.address) {
                    this.$refs.storeForm.errors.address.push(error.data.errors.address[0]);
                }
            }
            else if (error.status == 406) {
                this.$toast.error(this.$t('api.' + error.data.message));
            }
        },
        delete(id) {
            this.deleteModal(() => {
                UserModelFilterService.remove(id)
                             .then(response => {
                                 this.filter();
                                 this.$toast.success(this.$t('api.' + response.data.message));
                             })
                             .catch(error => {
                                 this.$toast.error(this.$t('api.' + error.data.message));
                             });
            });
        },
        async createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        async updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        // Show
        showForm(id) {
            this.formId = id
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
        createFormShow() {
            this.formId = null
            this.form = {};
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
    }
}
</script>

