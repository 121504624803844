<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-form-group class="col-12" :label="$t('staff')">
                    <ValidationProvider name="user_id" rules="required" v-slot="{ errors }">
                        <staff-auto-complete
                            v-model="form.user_id"
                            :validateError="errors[0]"/>
                    </ValidationProvider>
                </b-form-group>
                <b-form-group class="col-12" :label="$t('user_model')">
                    <ValidationProvider name="model" rules="required" v-slot="{ errors }">
                        <parameter-selectbox
                            code="user_filter_models"
                            v-model="form.model"
                            :validateError="errors[0]" />
                    </ValidationProvider>
                </b-form-group>
                <b-form-group class="col-12" :label="$t('user_filter_type')">
                    <ValidationProvider name="filter_type" rules="required" v-slot="{ errors }">
                        <parameter-selectbox
                            code="user_filter_types"
                            v-model="form.filter_type"
                            :validateError="errors[0]"
                        ></parameter-selectbox>
                    </ValidationProvider>
                </b-form-group>
                <b-form-group v-if="form.filter_type == 'campus_id'" class="col-12" :label="$t('campus')">
                    <ValidationProvider name="filter_value" rules="required" v-slot="{ errors }">
                        <campus-selectbox
                            v-model="form.filter_value"
                            :validateError="errors[0]" />
                    </ValidationProvider>
                </b-form-group>
                <b-form-group v-if="form.filter_type == 'nationality'" class="col-12" :label="$t('nationality')">
                    <ValidationProvider name="filter_value" rules="required" v-slot="{ errors }">
                        <user-model-filter-nationality-selectbox
                            v-model="form.filter_value"
                            :validateError="errors[0]"
                        ></user-model-filter-nationality-selectbox>
                    </ValidationProvider>
                </b-form-group>
                <b-form-group class="col-12" :label="$t('status')">
                    <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                        <status-selectbox
                            v-model="form.status"
                            :validateError="errors[0]" />
                    </ValidationProvider>
                </b-form-group>
            </b-row>
            <div class="col-12 mt-3 d-flex justify-content-center">
                <b-button @click="createForm"
                          :disabled="formLoading"
                          type="button"
                          variant="primary"
                          class="btn-lg mr-2">
                    {{ $t('save').toUpperCase() }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>

    import {ValidationProvider, ValidationObserver} from "vee-validate"
    import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete"
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
    import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox"
    import UserModelFilterNationalitySelectbox from "@/components/interactive-fields/UserModelFilterNationalitySelectbox"
    import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox"
    import UserModelFilterService from "@/services/UserModelFilterService";

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            StaffAutoComplete,
            ParameterSelectbox,
            CampusSelectbox,
            UserModelFilterNationalitySelectbox,
            StatusSelectbox
        },
        data() {
            return {
                formLoading: false,
                form: {
                    user_id: null,
                    model: null,
                    filter_type: null,
                    filter_value: null,
                    status: null
                }
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    this.form.status = this.form.status == 'a' ? 1 : 0
                    UserModelFilterService.store(this.form)
                                 .then((response) => {
                                     this.$toast.success(this.$t("api." + response.data.message));
                                     this.$emit("createFormSuccess")
                                 })
                                 .catch((error) => {
                                     this.showErrors(error, this.$refs.formModalValidate)
                                 });
                }
            }
        }
    }
</script>
