<template>
    <div>
        <b-row>
            <b-form-group class="col-12" :label="$t('staff')">
                {{ formData.user ? formData.user.name + ' ' + formData.user.surname : '-' }}
            </b-form-group>
            <b-form-group class="col-12" :label="$t('user_model')">
                {{ formData ? formData.model_name : '-' }}
            </b-form-group>
            <b-form-group class="col-12" :label="$t('user_filter_type')">
                {{ formData ? formData.filter_type_name : '-' }}
            </b-form-group>
            <b-form-group class="col-12" :label="$t('user_filter_value')">
                {{ formData ? formData.filter_value_name : '-' }}
            </b-form-group>
            <b-form-group class="col-12" :label="$t('status')">
                {{ formData.status == 1 ? $t('active') : $t('passive') }}
            </b-form-group>
        </b-row>
    </div>
</template>

<script>

import UserModelFilterService from "@/services/UserModelFilterService";

export default {
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formData: {}

        }
    },

    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            this.formLoading = true;
            UserModelFilterService.show(id)
                                  .then((response) => {
                                      this.formData = response.data.data;
                                  })
                                  .catch((error) => {
                                      if (error.data.message) {
                                          this.$toast.error(this.$t("api." + error.data.message));
                                      }
                                  });
        },
    }
}
</script>
